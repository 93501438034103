import React from 'react'
import { CFSelect } from 'components'
import { dispatch } from 'store'

export default class SubscribeRestaurantLocation extends React.PureComponent {
  locationId = null
  unsubscribe = null
  componentDidMount() {
    dispatch.restaurant.setLocationId('RWUL9hPkgA2jI9xM92ho')
    dispatch.restaurant.subscribeRestaurantLocation()
  }
  render() {
    return null
  }
}
