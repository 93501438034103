import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import {
  downloadButton,
  hero,
  heroText,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          image={`url(${mobileHero}) top / cover no-repeat`}
          column
          justifyStart
        >
          <CFView textCenter w="100%" mt="80px">
            <CFView>
              <CFImage
                w="300px"
                src={heroText}
                alt="Sapporo Japanese Kitchen hero text"
                zIndex={98}
              />
            </CFView>
            <CFView pulsate mt="30px">
              <OrderPickupButton />
            </CFView>
            <a href={`http://onelink.to/sapporodelta`}>
              <CFView hover mt="10px">
                <CFImage
                  src={downloadButton}
                  w="72%"
                  maxWidth="=250px"
                  alt="About"
                />
              </CFView>
            </a>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          center
        >
          <CFView column center mt="20px">
            <CFImage
              w="415px"
              src={heroText}
              alt="Sapporo Japanese Kitchen hero text"
              zIndex={98}
            />
            <CFView pulsate mt="40px">
              <OrderPickupButton />
            </CFView>
            <a href={`http://onelink.to/sapporodelta`}>
              <CFView hover mt="10px">
                <CFImage
                  src={downloadButton}
                  w="100%"
                  maxWidth="290px"
                  alt="About"
                />
              </CFView>
            </a>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
