import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { locationMap, mobileMap } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect w="100%" selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView w="100%">
          <MobileScreen>
            <CFView mt="-5px">
              <CFLink
                target="_blank"
                href="https://g.page/sapporokitchen?share"
              >
                <CFImage
                  src={mobileMap}
                  w="100%"
                  alt="Sapporo Japanese Kitchen Map"
                />
              </CFLink>
            </CFView>
          </MobileScreen>
          <DefaultScreen w="100%">
            <CFLink
              target="_blank"
              w="100%"
              href="https://g.page/sapporokitchen?share"
            >
              <CFImage
                src={locationMap}
                w="100%"
                alt="Sapporo Japanese Kitchen Kitchen Map"
              />
            </CFLink>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
