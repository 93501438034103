import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { foodGallery, mobileGallery } from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          {/* <CFImage src={mobileGallery} alt="Food Gallery" w="100%" mt="30px" /> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto" w="100%">
          {/* <CFImage src={foodGallery} alt="Food Gallery" w="100%" /> */}
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
